<template>
  <div class="page container p-4">
    <div class="columns">
      <div class="column is-offset-3-tablet is-6-tablet">
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            src="@/assets/img/anon-avatar.png"
            alt="regístrate"
          >
        </div>
        <b-field>
          <b-input
            ref="firstname"
            v-model="form.firstname"
            type="text"
            placeholder="Nombre"
            required
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="lastname"
            v-model="form.lastname"
            type="text"
            placeholder="Apellidos"
            required
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-input
            ref="email"
            v-model="form.username"
            type="email"
            placeholder="Email"
            required
            maxlength="100"
            @blur="validateForm"
          />
        </b-field>
        <b-field>
          <b-checkbox
            ref="privacyPolicy"
            v-model="form.legal_accepted"
            @input="legalInputChanged"
          >
            <b-button
              class="px-0"
              type="is-text"
              @click="openLegalModal"
            >
              Acepto la política de privacidad
            </b-button>
          </b-checkbox>
          <div
            v-if="legalError"
            class="help is-danger"
          >
            {{ legalError }}
          </div>
        </b-field>
        <div class="mt-3">
          <b-button
            type="is-primary is-uppercase"
            expanded
            @click="register"
          >
            Regístrate
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LegalContent from '@/assets/legal/privacy-policy.json'

export default {
  title: 'Registro',
  name: 'RegisterSimple',
  components: {},
  data: function () {
    return {
      isValid: false,
      schools: [],
      form: {
        firstname: '',
        lastname: '',
        username: '',
        legal_accepted: false
      },
      legalError: null,
      legalText: LegalContent
    }
  },
  computed: {},
  methods: {
    validateForm() {
      const validateFields = ["firstname", "lastname", "email"];
      let isValidFormData = true
      let legalValidation = true

      validateFields.forEach((item) => {
        if (this.$refs.hasOwnProperty(item)) {
          let itemIsValid = this.$refs[item].checkHtml5Validity()

          isValidFormData = isValidFormData && itemIsValid
        }
      })

      if(!this.form.legal_accepted){
        this.legalError = 'Debes aceptar nuestra política de privacidad'
        legalValidation = false
      }

      this.isValid = isValidFormData && legalValidation
    },
    async register() {
      this.validateForm()

      if (this.isValid && this.form.legal_accepted === true) {
        try {
          const userData = {
            firstname: this.form.firstname,
            lastname: this.form.lastname,
            email: this.form.username
          }

          await this.$store.dispatch('initLoading')
          await this.$api.user.registerMinimal(userData)
          await this.$store.dispatch('finishLoading')

          this.$buefy.dialog.alert({
            title: 'Registro',
            message: 'Te has registrado correctamente.<br /><br />Ya puedes identificarte en musicaeduca.online',
            confirmText: 'Cerrar'
          })

          await this.$router.push('/')
        } catch (error) {
          await this.$store.dispatch('finishLoading')
          const data = error.response.data
          await this.$msg.error(data.message)
        }
      }
    },
    openLegalModal() {
      const currentYear = ''+(new Date()).getFullYear()
      this.$buefy.dialog.alert({
        title: this.legalText.title,
        message: this.legalText.content.split('{currentYear}').join(currentYear),
        confirmText: 'Cerrar'
      })
    },
    legalInputChanged(value){
      if(value && this.legalError){
        this.legalError = ''
      }
    }
  }
}
</script>
